import React from "react"
import styles from "./Shop.module.scss"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Button from "../Button"
import PropTypes from "prop-types"
import data from "../../data/sectionTexts"

const getContent = graphql`
  query {
    allContentfulProdejna {
      edges {
        node {
          popisHlavniStranka {
            json
          }
          hlavniFotka {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const Shop = ({ secondary, hideUnderlay }) => {
  const { allContentfulProdejna: { edges }} = useStaticQuery(getContent)
  const { hlavniFotka, popisHlavniStranka } = edges[0].node
  const { title, button } = data.shop;

  return (
    <section className={`${styles.shop} ${secondary ? "negative-mar-top" : ""}`}>
      <div className={styles.shopWrapper}>
        <BackgroundImage
          fluid={hlavniFotka.fluid}
          className={styles.imgWrapper}
        />
        <div className={styles.textWrapper}>
          <h2 className={styles.heading}>
            {title.thin}<span className="bold">{title.bold}</span>
          </h2>
          <div className={styles.text}>
            {documentToReactComponents(popisHlavniStranka.json)}
          </div>
          <div className={styles.button}>
            <Button link="/prodejna" color="blue">
              {button}
            </Button>
          </div>
        </div>
      </div>
      {!hideUnderlay && <div className={styles.underlay}/>}
    </section>
  )
}

export default Shop

Shop.propTypes = {
  secondary: PropTypes.bool,
  hideUnderlay: PropTypes.bool,
}
